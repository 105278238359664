.container {
  position: relative;
  /* overflow: hidden; */
}

.topbar,
.index-topbar,
.left-section {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0rem 0rem 0.5rem;
}
.index-topbar {
  display: flex;
  flex-flow: row wrap;
}
.index-topbar > * {
  margin-left: 5px;
}

@media (min-width: 600px) {
  .topbar {
    justify-content: space-between;
    padding: 2rem 0.5rem 0.5rem;
  }
  .left-section {
    justify-content: flex-start;
  }
}

.main-body {
  width: 100%;
  height: 100%;
  padding: 1rem 1.2rem;

  overflow: hidden;
}
.main-body.index > * {
  padding-top: 2rem;
}
.filter-item {
  padding: 0.5rem;
}
.material-container {
  width: 100%;
}
.material-header {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  overflow-x: auto;
}
.material__loading {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}
.material__loading > * {
  margin-top: 4rem;
}
.material-items {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
}
.material-items > * {
  margin-bottom: 1rem;
}
@media (min-width: 945px) {
  .material-items {
    justify-content: flex-start;
    flex-flow: row wrap;
  }
  .material-items > * {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
}
.item {
  display: flex;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 5px;
  padding: 1.2rem 0.5rem;
  /* margin-top: 0.5rem; */
  width: 300px;
  min-width: 250px;
}
@media (min-width: 600px) {
  .item {
    margin-bottom: 0rem;
    width: 280px;
  }
}
.item:hover {
  cursor: pointer;
  border: 1px solid #00962c;
}
.item-img {
  width: 50;
  height: 50;
  padding: 0.6rem 0.8rem;
  background-color: #00962c1a;
  border-radius: 15px;
}
.item-text {
  flex: 1;
  margin: 0 0.6rem;
}
.item-variant {
  display: flex;
}
.details-container {
  background-color: #fff;
}
.details-card-container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}
@media (min-width: 600px) {
  .details-card-container {
    flex-flow: row wrap;
  }
}
.button-container {
  margin-top: 3rem;
}

.details-topbar {
  justify-content: space-between;
}
@media (min-width: 600px) {
  .button-container {
    margin-top: 0rem;
  }
}
.details-card {
  width: 100%;
  height: 130px;
  padding: 1.3rem;
  border-radius: 10px;
  border: 2px solid #f9f9f9;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: space-between;
}
.details-card b {
  text-transform: capitalize;
}
.details-card:not(:last-child) {
  background-color: #f9f9f9;
  margin-bottom: 1rem;
}
@media (min-width: 600px) {
  .details-card {
    width: 280px;
  }

  .details-card b {
    text-align: right;
  }
  .details-card:not(:last-child) {
    margin-bottom: 0rem;
  }
}
.details-card > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8em;
}
.details-card > span:not(:last-child) {
  padding-bottom: 1em;
}
.details-container .main-body {
  margin-top: 2rem;
}
.body-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  margin-bottom: 1.5rem;
}

.table {
  margin-top: 1.5rem;
  border-top: 1px solid #ddd;
}
.table::-webkit-scrollbar {
  height: 2px;
}
@media (min-width: 600px) {
  .body-topbar {
    margin-bottom: 1rem;
  }
  .table {
    margin-top: 0rem;
  }
}

.table-row {
  font-size: 0.9rem;
  background-color: #fff;
}
.table-row:hover {
  opacity: 0.8;
  cursor: pointer;
}
