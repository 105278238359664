.auth-container {
  max-width: calc(450px + 2rem);
  padding: 1rem;
  margin: 0 auto;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
}
.auth-container h1 {
  font-weight: 600;
  font-size: 1.4rem;
  text-align: left;
  margin-top: 9rem;
  margin-bottom: 2.5rem;
}
.auth-container footer {
  text-align: center;
}
.logo {
  display: block;
  margin: 0 auto;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.auth-button__loading {
  max-height: 100%;
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.form__div {
  overflow-y: auto;
  overflow-x: hidden;
}
