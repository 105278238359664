.container {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
.topbar {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
}
.details-container {
  background-color: #fff;
  padding: 0.8rem;
  height: 100%;
}
.details-card-container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}
.details-topbar {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  margin-bottom: 1rem;
}
@media (min-width: 600px) {
  .details-card-container {
    flex-flow: row wrap;
  }
  .details-topbar {
    justify-content: space-between;
    align-items: center;
  }
}

.details-topbar > *:not(:first-child) {
  padding-top: 0.5rem;
}
.details-card {
  background-color: #f9f9f9;
  width: 100%;
  padding: 1.3rem;
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: space-between;
}
.details-card b {
  text-align: right;
}
.details-card:not(:last-child) {
  margin-bottom: 1rem;
}

.details-card > span,
.span {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 0.8em;
}

.span {
  width: 100%;
  /* align-items: center; */
}

.details-card b {
  text-transform: capitalize;
  width: 60%;
}
.details-card > span:not(:last-child) {
  padding-bottom: 1em;
}

.details-card > span b,
.span b {
  text-align: left;
}
.table-row:hover {
  opacity: 0.8;
  cursor: pointer;
}

@media (min-width: 1000px) {
  .details-card {
    width: 47.5%;
    margin: 1rem auto 0;
  }

  .details-card:not(:last-child) {
    margin-bottom: 0rem;
  }
  .details-topbar > *:not(:first-child) {
    padding-top: 0rem;
  }
  .details-card > span,
  .span {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 0.8em;
  }

  .span {
    align-items: center;
  }
  .details-card > span b,
  .span b {
    text-align: right;
  }
}
/* .footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
}
@media (min-width: 600px) {
  .footer {
    justify-content: flex-end;
  }
} */

.profile-card {
  background-color: #f9f9f9;
  padding: 1rem;
  width: 100%;
  margin: 2% auto 0;
  /* display: flex;
  flex-flow: row wrap;
  align-items: center; */
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

.profile--card-content {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 1rem;
}

.avatar {
  display: block;
  border-radius: 1rem;
  /* margin: 2rem auto; */
  position: relative;
  width: 49%;
}
.profile-content {
  margin-left: 2rem;
}
.circle {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 50px solid #00962c11;
}
.circle:nth-child(odd) {
  top: -40%;
  right: -20%;
}
.circle:nth-child(even) {
  bottom: -20%;
  left: -10%;
  border: 40px solid #00962c11;
}

.avatar {
  display: block;
  border-radius: 1rem;
  position: relative;
  width: 49%;
}
@media (max-width: 600px) {
  .avatar {
    width: 100%;
    margin-bottom: 1rem;
  }
}
.profile-content {
  margin-left: 2rem;
}
.circle {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 50px solid #00962c11;
}
.circle:nth-child(odd) {
  top: -40%;
  right: -20%;
}
.circle:nth-child(even) {
  bottom: -20%;
  left: -10%;
  border: 40px solid #00962c11;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
}
@media (min-width: 600px) {
  .footer {
    justify-content: flex-end;
  }
}
.table {
  width: 100%;
}

.table-row:hover {
  opacity: 0.8;
  cursor: pointer;
}
.table-row {
  text-transform: capitalize;
  font-size: 1rem;
  background-color: #fff;
}
.table-row:hover {
  opacity: 0.8;
  cursor: pointer;
}
