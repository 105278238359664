h4,
h5,
p {
  padding: 0;
  margin: 0;
}
.cart {
  /* background-color: #fff; */
  background-color: var(--prinaryAccent);
  height: 85vh;
  width: 100%;
  min-width: 250px;
  padding: 0rem;
  /* padding-bottom: 5rem; */
  margin-left: 0rem;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 99999;
  /* display: none; */
}
.body {
  height: inherit;
  flex: 1;
  position: relative;
  z-index: inherit;
}
.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media (min-width: 767px) {
  .cart {
    height: 85vh;
    width: 330px;
    min-width: 250px;
    padding: 0.5rem 0rem;
    margin-left: 1rem;
    display: block;
    /* border: 1px solid #00962c; */
  }
}
.empty-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.empty-box .desc {
  padding: 10px;
  opacity: 0.5;
}
.cart-items {
  padding: 0 0.8rem;
  margin-top: 1rem;
  height: 55vh;
  overflow: hidden;
  overflow-y: auto;
  /* flex: 1; */
}
.footer {
  /* position: fixed; */
  width: 100%;
}
.footer-action > * {
  border-radius: 0;
}
.footer .sub-total {
  background-color: #f9f9f9;
  width: 100%;
  padding: 0.8rem;
  margin: 0 auto;
  border-radius: 10px;
}

/* CART ITEM */

.cart-item {
  display: flex;
  align-items: flex-end;
  border-radius: 10px 10px 0 0;
  margin-bottom: 0.5rem;
  overflow: hidden;
  /* border-bottom: 1px solid #d1d1d1; */
  border: 1px solid #999;
}
.item-content {
  width: 100%;
  background: #888;
}
.cart-top {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  text-align: right;
  margin-bottom: 1rem;
  padding: 1rem 0.5rem 0;
}
/* .cart-content div {
  display: flex;
  justify-content: space-between;
} */

.cart-content {
  padding-top: 0.5rem;
  border-radius: 20px 0 0 0;
  background-color: #fff;
  padding: 1rem 0.5rem 0;
}
.item-content p {
  font-size: 0.9rem;
}

.cart-footer {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 767px) {
  .cart-footer {
    padding-top: 1rem;
  }
}
