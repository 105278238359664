.search {
  border-top: 0.5px solid #c0bfbf;
  border-bottom: 0.5px solid #c0bfbf;
  padding: 1rem;
}
.search > div {
  max-width: 12rem;
}
.container {
  padding: 1rem;
}
.card {
  background: #fff;
  border-radius: 1rem;
  padding: 1rem;
}
.card > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card address {
  margin-bottom: 0;
}
.table {
  background-color: #fff;
}
.table-row {
  font-size: 0.9rem;
  background-color: #fff;
}
.table-row:hover {
  opacity: 0.8;
  cursor: pointer;
}
