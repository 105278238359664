*::-webkit-scrollbar {
  height: 2px;
}
.container {
  /* background-color: #fff; */
  width: 100%;
  padding: 1rem 1rem;
  height: 100%;
  /* overflow-y: auto; */
  overflow: hidden;
  overflow-y: auto;
  border-radius: 9px;
}

.item-display {
  text-transform: capitalize;
  font-weight: 500;
  font-size: medium;
}

.item-display:hover {
  transition: 0.2s;
  opacity: 0.7;
}

@media (max-width: 767px) {
  .container {
    width: 100vw;
  }
}
.table-wrap {
  overflow-x: auto;
}
/* .invoice {
  border: 1px soid red;
  background-color: yellow;
  width: 100vw;
} */

.invoice-image {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.2;
  z-index: 1;
}
.invoice__header {
  display: flex;
  margin-bottom: 1rem;
  /* flex-wrap: wrap; */
  flex-direction: column;
}
.invoice__header address {
  display: flex;
  /* border: 2px solid red; */
  width: 100%;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;
  /* column-gap: 3rem; */
  margin-top: 0.5rem;
}
.invoice__header .aside-card {
  background-color: var(--prinaryAccent);
  border-radius: 10px;
  padding: 10px;
  font-size: 0.8rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.6rem;
  /* font-weight: 600; */
}
@media (min-width: 1500px) {
  .invoice__header address {
    justify-content: space-between;
  }
}
.invoice__header_invoice p {
  font-size: small;
  margin-bottom: 0rem;
}

.invoice__header_invoice p span {
  font-weight: 700;
  display: block;
  font-size: 1rem;
}
.invoice > table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 1rem;
}

.invoice table th,
.invoice table td {
  padding: 0.25rem;
  text-align: start;
  border: 1px solid #ccc;
}
.invoice table tfoot td:nth-child(-n + 3) {
  border: none;
}
.invoice {
  font-size: small;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  /* font-family: "arial"; */
}
.invoice__header__logo {
  padding-top: 1rem;
}
.invoice address p {
  margin-bottom: 0;
}
.invoice__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  /* padding: 0 3rem; */
  overflow-x: hidden;
  max-width: 100%;
}
.invoice__footer table tr td {
  text-align: end;
}

.topbar {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
}
.details-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

@media (min-width: 600px) {
  .invoice {
    font-size: smaller;
    padding: 3rem;
    padding-top: 0;
  }
  .invoice__header {
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}
.matadd {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;
  /* font-size: 1rem; */
  line-height: 1.5;
}
.topbar {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
}
.details-container {
  /* background-color: #fff; */
  padding: 0.8rem;
  height: 90vh;
  border-radius: 10px;
  overflow-y: auto;
  /* overflow-x: hidden; */
  max-width: 100vw;
  padding-bottom: 10%;
}
.details-card-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  background-color: #f9f9f9;
  padding: 1rem;
}
.details-topbar {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
@media (min-width: 600px) {
  .details-card-container {
    flex-flow: row wrap;
  }
  .details-topbar {
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 600px) {
  .details-wrapper {
    flex-direction: column;
    gap: 1rem;
  }
}

.details-topbar > *:not(:first-child) {
  padding-top: 0.5rem;
}
.details-card {
  background-color: #f9f9f9;
  /* width: 100%; */
  padding: 1.3rem;
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: space-between;
}
.details-card b {
  text-align: right;
}
.details-card:not(:last-child) {
  margin-bottom: 1rem;
}

.details-card > span {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 0.8em;
}
.details-card b {
  text-transform: capitalize;
}
.details-card > span:not(:last-child) {
  padding-bottom: 1em;
}

.details-card > span b {
  text-align: left;
}
.invoice-table-container {
  overflow-x: auto;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 10px;
}
.invoice-table-container table {
  min-width: auto;
}
.table-row:hover {
  opacity: 0.8;
  cursor: pointer;
}
.invoice-table-container thead,
.invoice-table-container th {
  background-color: var(--primaryBlack);
  color: white;
  padding: 0.3rem;
}
.invoice-table-container * {
  border: none;
  outline: none;
}
.invoice-table-container .primary-row {
  background-color: var(--prinaryAccent);
}
@media (min-width: 600px) {
  .invoice-table-container table {
    min-width: 100%;
  }
}

@media (min-width: 1000px) {
  .details-card:not(:last-child) {
    margin-bottom: 0rem;
  }
  .details-topbar > *:not(:first-child) {
    padding-top: 0rem;
  }
  .details-card > span {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8em;
  }
  .details-card > span b {
    text-align: right;
  }
}
.transaction-details-container {
  margin-bottom: 3%;
}
.profile-card {
  background-color: var(--prinaryAccent);
  padding: 1rem;
  width: 100%;
  margin: 2% auto 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

.profile-top {
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 20px;
  overflow: hidden;
}
.schedule-details {
  width: 90%;
  margin: 0 auto;
}

.avatar {
  display: block;
  border-radius: 1rem;
  /* margin: 2rem auto; */
  position: relative;
  width: 49%;
}
.profile-content {
  /* margin-left: 2rem; */
  text-transform: capitalize;
}
.name {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.65rem;
  font-size: 1rem;
}
/* .profile-content .title,
.profile-content .price {
  font-weight: 700;
  font-size: 2rem;
  margin-top: 1rem;
}
.profile-content .price {
  color: #00962c;
  margin-top: 0rem;
} */
.circle {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 50px solid #00962c11;
}
.circle:nth-child(odd) {
  top: -40%;
  right: -20%;
}
.circle:nth-child(even) {
  bottom: -20%;
  left: -10%;
  border: 40px solid #00962c11;
}

.table-container {
  overflow-x: auto;
  /* margin-right: 1rem; */
  width: 100%;
}
.table-row {
  text-transform: capitalize;
  font-size: 0.9rem;
  width: 100%;
  /* padding: 0 0.5rem; */
  background-color: #fff;
}
.table-row:hover {
  opacity: 0.8;
  cursor: pointer;
}

@media (max-width: 600px) {
  .avatar {
    width: 100%;
  }
}

.time-up {
  opacity: 0.4;
  filter: grayscale(100%);
}

.table-container {
  overflow-x: auto;
  /* margin-right: 1rem; */
  width: 100%;
}
.table-row {
  font-size: 0.9rem;
  width: 100%;
  /* padding: 0 0.5rem; */
  background-color: #fff;
}
.table-row:hover {
  opacity: 0.8;
  cursor: pointer;
}

.owing-card {
  width: 80%;
  padding: 3rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem auto 0;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 3px 10px #00000022;
}
.owing-card .circle {
  top: -25%;
  right: -15%;
  opacity: 0.5;
}
.owing-card > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.owing-card .amount-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.owing-card .amount-text .amount {
  font-size: 3rem;
  font-weight: 600;
}

.modalArea  {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  
}
.modalArea div {
  width: 80%;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .owing-card {
    width: 90%;
  }
  .owing-card .amount-text {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .owing-card .amount-text .amount {
    font-size: 2.5rem;
  }
}
.sales-invoice {
  font-size: 0.75rem;
  opacity: 0.8;
}
