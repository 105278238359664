@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap");

@font-face {
  font-family: "Circular STD";
  src: url("./assets/fonts/Circular\ Std\ Bold/Circular\ Std\ Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Circular STD";
  src: url("./assets/fonts/Circular\ Std\ Black/Circular\ Std\ Black.ttf")
    format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Circular STD";
  src: url("./assets/fonts/Circular\ Std\ Bold\ Italic/Circular\ Std\ Bold\ Italic.ttf")
    format("truetype");
  font-weight: 700;
  font-style: italic;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
@font-face {
  font-family: "Circular STD";
  src: url("./assets/fonts/Circular\ Std\ Book/Circular\ Std\ Book.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Circular STD";
  src: url("./assets/fonts/Circular\ Std\ Medium/Circular\ Std\ Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}
:root {
  --primary: #00962c;
  --primaryDark: #007d25;
  --primaryDarker: #054e31;
  --prinaryAccent: #edfdf1;
  --primaryMuted: #d6ffe2;
  --primaryText: #65c51c;
  --primaryBlack: #001e09;
  --secondaryBlack: #383838;
  --white: #fff;
  --whiteAccent: #f9fafb;

  iframe#webpack-dev-server-client-overlay {
    display: none !important;
  }
}
* {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}
.text-white {
  color: #fff;
}
.text-center {
  text-align: center;
}

/* a,
a:visited,
a:hover {
  color: var(--primary);
  text-decoration: none;
} */
.bg-white {
  background-color: #fff;
}
.w-100 {
  width: 100%;
}
.p-1 {
  padding: 1rem;
}
.ml-auto {
  margin-left: auto;
}
.c-end {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}
.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.s-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.table-row ::first-letter {
  text-transform: capitalize;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #92c064;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00962c;
}

.ant-card-meta-title {
  text-transform: capitalize !important;
}

.end--buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

.ant-modal-title {
  text-transform: capitalize !important;
}
