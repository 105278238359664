.modal > * {
  border-radius: 10px;
  width: 700px;

  /* max-width: 300px; */
  border: 1px solid red;
}

.quantityWrapper {
  display: flex;
  gap: 5px;
}
.removeQuantity {
  color: red;
}
