.container {
  width: 100%;
  height: 100%;
  padding: 0 0.5rem 1rem;
  border-radius: 10px;
  overflow: hidden;
  overflow-y: auto;
}
.topbar {
  display: flex;
  padding: 0.5rem 0;
}
.topbar span {
  opacity: 0.5;
}
.request-container {
  width: 100%;
  margin: 0 auto;
  /* display: flex;
  justify-content: flex-start;
  border: 1px solid yellow; */
  display: grid;
  /* grid-template-columns: repeat(auto, 1fr); */
  grid-gap: 10px;
}

.c-class {
  text-transform: capitalize;
}

.container .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: red;
}
@media (min-width: 600px) {
  .request-container {
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}

/* DETAILS SECTION */

.cta {
  width: 100%;
  justify-content: center;
}

.details-card {
  background-color: #f9f9f9;
  width: 100%;
  margin: 2rem auto 0;
  padding: 2rem 1.1rem;
  border-radius: 20px;
}
.details-card span {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  text-align: left;
}
.details-card span:not(:last-child) {
  margin-bottom: 10px;
}

.details-card span > * {
  word-wrap: break-word;
}
.details-card span b a {
  margin-left: 0.5rem;
}
.details-card ul {
  list-style-type: none;
  padding-left: 0;
}
.details-card ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  padding: 0.5rem;
  background-color: #eaeaea;
  border-radius: 10px;
  margin-bottom: 0.5rem;
}

@media (min-width: 767px) {
  .details-card span {
    width: 100%;
    flex-flow: row wrap;
    justify-content: space-between;
    font-size: 1rem;
  }
  .details-card {
    width: 80%;
  }
  .details-card span b {
    text-align: right;
    width: 50%;
    text-transform: capitalize;
  }
  .details-card ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: auto;
  }

  .cta {
    display: flex;
    justify-content: flex-end;
  }
}

.set-availability {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.table-row {
  font-size: 0.9rem;
  background-color: #fff;
}
.table-row:hover {
  opacity: 0.8;
  cursor: pointer;
}
