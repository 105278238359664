.container {
  background-color: #fff;
  padding: 1rem 1rem;
  width: 100%;
  overflow: hidden;
}
* {
  transition: 0.35s;
}
/* CARD */
.cards-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  overflow: auto;
}
.card {
  background-color: #fafafa;
  /* height: 150px; */
  min-width: 230px;
  width: auto;
  height: 200px;
  /* min-width: 230px; */
  border-radius: 7.5px;
  box-shadow: 0px 2px 5px #ddd;
  position: relative;
  overflow: hidden;
  margin-right: 1rem;
}
.card:hover {
  transform: scale(1.03);
  box-shadow: 0px 3px 10px #eee;
}
.card:hover .color {
  transform: scale(1, 3);
  border-radius: 0vw;
}
.card:not(:last-child) {
  margin-right: 1rem;
}
.card-details {
  margin: 1rem 0 0 1rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
.card-details > .card-image {
  margin-bottom: 0.5rem;
  padding: 10px;
  border-radius: 30%;
}
.color {
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0px;
  border-radius: 100% 100% 0 0;
  transform-origin: 50% 50%;
  /* z-index: -1; */
}
.stat {
  width: auto;
  bottom: 0px;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 1rem;
  padding-top: 0.5rem;
}

@media (min-width: 767px) {
  .cards-container {
    align-items: center;
    overflow: hidden;
    overflow-x: auto;
  }
  .card {
    /* min-width: 220px; */
    width: auto;
    margin-right: 1rem;
  }
}

.filter {
  margin: 1rem 0;
}
.graph-container {
  display: flex;
  flex-flow: row wrap;
  overflow-x: auto;
  padding: 1rem;
}
.graph {
  width: 100%;
  background-color: #efefef77;
  /* background-color: #00962c33; */
  padding: 1rem;
  /* padding-bottom: 1rem; */
  border-radius: 5px;
  box-shadow: 0px 2px 5px #cdcdcd;
}
.graph:nth-child(2) {
  margin-top: 1rem;
}
.graph-bars {
  /* width: 100%; */

  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  overflow-x: auto;
  overflow-y: hidden;
  /* padding-bottom: 0.5rem; */
}
.graph-bars::-webkit-scrollbar {
  height: 2px;
}
.graph-bars > *:not(:last-child) {
  margin-right: 1.2em;
}
.graph-marks {
  height: 250px;
  width: 3px;

  margin-bottom: 1.3rem;
}
.bar {
  width: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bar-container {
  background-color: #987ef511;
  width: 15px;
  height: 250px;
  position: relative;
  border-radius: 10px 10px 0px 0px;
}
.bar-current-count,
.bar-minimum {
  width: 15px;
  background: -webkit-linear-gradient(#987ef5, #10c947dd);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  border-radius: 10px 10px 0px 0px;
  position: relative;
  overflow: visible;
  position: absolute;
  bottom: 0;
  /* z-index: 2222; */
}

.bar-current-count {
  background-color: #00962c33;
  border-radius: 3px;
  overflow: hidden;
}
.bar-title {
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
  text-align: right;
  white-space: nowrap;
  transform-origin: 100% 50%;
  /* transform: rotateZ(45deg); */
  margin-top: 0.3rem;
  /* display: none; */
}

.graph-foot-note {
  display: flex;
}
.graph-foot-content {
  margin-top: 1rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.graph-indicator {
  width: 30px;
  height: 30px;
  background-color: #00962c55;
  border-radius: 50%;
  margin-right: 0.6rem;
}
.cards-container::-webkit-scrollbar,
.graph-container::-webkit-scrollbar {
  height: 1px;
}
.graph-indicator-minimum {
  background-color: #00962c55;
}
@media (min-width: 880px) {
  .graph {
    width: 45%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .graph-bars {
    /* overflow-y: hidden; */
    height: auto;
    overflow-x: auto;
    padding: 1rem;
  }
  .graph:last-child {
    margin: 0 0 0 3rem;
  }
}
