.like-wrap,
.liked-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: #00000011; */
  border-radius: 20%;
  cursor: pointer;
}
.like-wrap > div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: transparent;
}
.like-wrap * {
  display: flex;
  justify-content: center;
  align-items: center;
}
.liked-wrap {
  opacity: 0.5;
}
.like-wrap p,
.liked-wrap p {
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
}
.like-wrap:active img {
  transition: 0.2s;
  margin-top: -8px;
}
.share-ui-icon {
  background-color: #00000011;
  border-radius: 35%;
  padding: 10px;
  font-size: 1.6rem;
  cursor: pointer;
}
.dislike {
  transform: rotateZ(180deg);
}
