.container {
  width: 100%;
  height: 100%;
  padding: 0 0.5rem 1rem;
  border-radius: 10px;
  overflow: hidden;
  overflow-y: auto;
}

.topbar {
  display: flex;
  padding: 0.5rem 0;
}

.request-container {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-gap: 10px;
}

.c-class {
  text-transform: capitalize;
}

.container .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: red;
}
@media (min-width: 600px) {
  .request-container {
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}

/* DETAILS SECTION */

.cta {
  width: 100%;
  justify-content: center;
}

.set-availability {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.table-row {
  font-size: 0.9rem;
  background-color: #fff;
}
.table-row:hover {
  opacity: 0.8;
  cursor: pointer;
}
