.container {
  width: 100%;
  overflow: hidden;
  /* border: 3px solid red; */
  position: relative;
  background-color: #e9e9e9;
}
.details-header-container {
  background: #00962c;
  width: 100%;
  font-size: 1.2rem;
  text-align: center;
}
.top-bar {
  padding: 1rem 0px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}
.header-content {
  height: 20vh;
  width: 70%;
  margin: 0rem auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-content * {
  color: #fff !important;
  font-weight: 700;
  line-height: 1.1;
}

.content {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  overflow: hidden;
  margin: 5rem auto;
}
.content > section {
  width: 100%;
  margin: 0 auto;
}
.post-wrapper {
  width: 100%;
}

.post-content {
  font-size: 1.3rem;
  padding: 1rem;
}

.content article {
  min-width: 100%;
  background-color: #ffffffaa;
  border-radius: 10px;
}
.post-banner {
  text-align: center;
}
.post-banner img {
  width: 40%;
  border-radius: 10px;
  text-align: center;
}
.user-interaction {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

@media (max-width: 700px) {
  .details-header-container {
    font-size: 1rem;
  }
  .header-content {
    width: 95%;
    padding: 2rem 0;
    height: auto;
  }
}
