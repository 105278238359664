.tab {
  display: flex;
  overflow: auto;
  transform: all 0.3s;
}
.tab a {
  display: inline-block;
  font-weight: bold;
  padding: 0.5rem 1.4rem;
  margin-bottom: 0.6rem;
  color: #000;
  border-radius: 5px;
  line-height: 1.2;
}
