.container {
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  /* border: 3px solid red; */
  position: relative;
  background-color: #fff;
}
.header-container {
  /* background: -webkit-linear-gradient(45deg, #001d4d, #001d4daa),
    url("/about-img.jpg"); */
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 100%;
  margin: 0 auto;
  /* margin-bottom: 8rem; */
  /* 00962caa */
}

.header-content {
  height: 20vh;
  width: 70%;
  margin: 4rem auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
}

.content {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.blog-wrapper {
  width: 100%;
  margin: 0rem auto;
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
}

.blog-item {
  border: 1px solid #00000022;
  border-radius: 10px;
  /* max-width: 400px; */
  /* width: 350px; */
  /* min-width: 250px; */
  /* width: 30px; */
  box-shadow: 0 4px 15px #312c2c11;
  background-color: #f9f9f9dd;
  overflow: hidden;
  transition: 0.2s;
  display: flex;
  flex-flow: row wrap;
  /* border: 2px solid transparent; */
  /* perspective: 100; */
}

/* .blog-item:hover {
  box-shadow: 4px 4px 30px #272d4e22;
  background: -webkit-linear-gradient(right, #f9f9f9, #fcfcfc);
  background-color: #f9f9f9;
  cursor: pointer;

} */

/* .blog-item:hover .blog-image {
  transition: 0.2s;
  transform: scale(1.1);
} */

.blog-post-header h2 {
  line-height: 1.1;
}

.blog-post-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog-image {
  cursor: pointer;
  position: relative;

  /* min-height: 300px; */
  height: 150px;
  text-align: center;
  width: 100%;
  /* overflow: hidden; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  /* margin-bottom: 3rem; */
  border-bottom: 1px solid #00000022;
}
.profile-image {
  position: absolute;

  bottom: -15%;
  left: 2%;
}
.profile-image img {
  border-radius: 50%;
}
.blog-text {
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  line-height: 1.2;
  width: 100%;
}
.blog-body {
  width: 70%;
}
.footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: flex-end;
  /* padding: 1rem 0; */
  margin-top: 1rem;
  font-size: 0.9rem;
  /* border: 1px solid red; */
}
.footer > span {
  font-size: 0.9rem;
  opacity: 0.5;
}

@media (max-width: 767px) {
  .blog-body {
    width: 100%;
  }
  .footer {
    justify-content: center;
  }
}
