.main {
  min-height: 100%;
}
.heading {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;
}
.avatar {
  display: block;
  border-radius: 1rem;
  /* margin: 2rem auto; */
}
.profile-card {
  background-color: #fff;
  padding: 1rem;
  width: 80%;
  margin: 2rem auto 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 5px 25px #eee;
  position: relative;
  overflow: hidden;
}

.avatar {
  /* display: block; */
  border-radius: 1rem;
  position: relative;
}
@media (max-width: 767px) {
  .profile-content {
    margin-top: 2rem;
  }
}
.circle {
  position: absolute;

  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 50px solid #00962c33;
}
.circle:nth-child(odd) {
  top: -40%;
  right: -20%;
}
.circle:nth-child(even) {
  bottom: -20%;
  left: -10%;
  border: 40px solid #00962c33;
}
.table {
  background: #fff;
  border-radius: 1rem;
  padding: 2rem;
  margin: 0.25rem auto;
  margin-top: 1rem;
  max-width: 500px;
  overflow: auto;
}
@media screen and (max-width: 480px) {
  .table {
    padding: 1rem;
    margin: 0.25rem;
  }
}
.table th {
  text-align: start;
  font-weight: 400;
}
.table table {
  width: 100%;
}
.table td {
  text-align: end;
  font-weight: bold;
}
.table td::first-letter {
  text-transform: uppercase;
}
