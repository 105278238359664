.spreadsheet-container {
  width: 80%;
  margin: 0 auto;
  text-transform: capitalize;
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.heading {
  border-radius: 10px;
  padding: 0;
  margin: 0;
  font-weight: 600;
}
.spreadsheet {
  border: 1px solid #f8f8f8;
  border-radius: 6px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 0 auto;
  background-color: #fff;
  overflow: auto;
}

.left-side {
  /* padding: 0.5rem; */
  margin: 0.7rem 0rem;
  text-align: center;
  width: 100%;
}
.right-side {
  border-collapse: collapse;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  border-left: 1px solid #aaa;
}

.right-content {
  width: fit-content;
  min-width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}
.right-content {
  /* border-bottom: 1px solid #aaa; */
  background-color: #f7f7f7;
}
.description {
  min-width: 300px;
  /* border: 1px solid red; */
  /* flex: 1; */
  margin-right: 2rem;
}

@media (max-width: 767px) {
  .spreadsheet-container {
    width: 100%;
  }
  .spreadsheet {
    width: auto;
    overflow: auto;
  }
}

.table-row {
  font-size: 0.9rem;
  background-color: #fff;
}
