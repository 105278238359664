*::-webkit-scrollbar {
  height: 2px;
}
.container {
  background-color: #fff;
  width: 100%;
  padding: 1rem 1rem;
  height: 100%;
  /* overflow-y: auto; */
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 9px;
}

@media (max-width: 767px) {
  .container {
    width: 100vw;
  }
}
.table-wrap {
  overflow-x: auto;
}
/* .invoice {
  border: 1px soid red;
  background-color: yellow;
  width: 100vw;
} */

.invoice__header {
  display: flex;
  margin-bottom: 1rem;
  /* flex-wrap: wrap; */
  flex-direction: column;
}
.invoice__header_invoice p {
  font-size: small;
  margin-bottom: 1rem;
}

.invoice__header_invoice p span {
  font-weight: 700;
  display: block;
  font-size: 1rem;
}
.invoice > table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 1rem;
}

.invoice table th,
.invoice table td {
  padding: 0.25rem;
  text-align: start;
  border: 1px solid #ccc;
}
.invoice table tfoot td:nth-child(-n + 3) {
  border: none;
}
.invoice {
  font-size: small;
  max-width: 100%;
  overflow: hidden;
}
.invoice address > * {
  margin-bottom: 0;
}
.invoice__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  /* padding: 0 3rem; */
  overflow-x: hidden;
  max-width: 100%;
}
.invoice__footer table tr td {
  text-align: end;
}

.topbar {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
}
.details-container {
  background-color: #fff;
  padding: 0.8rem;
  border-radius: 10px;
  height: 100%;
  overflow-y: auto;
  max-width: 100%;
}
.details-card-container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}
@media (min-width: 600px) {
  .details-card-container {
    flex-flow: row wrap;
    justify-content: space-around;
  }
  .invoice {
    font-size: smaller;
    padding: 3rem;
  }
  .invoice__header {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
}
.details-topbar {
  justify-content: space-between;
  flex-flow: row wrap;
}
.details-topbar > *:not(:first-child) {
  padding-top: 0.5rem;
}
.details-card {
  background-color: #f9f9f9;
  width: 100%;
  padding: 1.3rem;
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: space-between;
}
.details-card b {
  text-align: right;
}
.details-card:not(:last-child) {
  margin-bottom: 1rem;
}
@media (min-width: 600px) {
  .details-card {
    width: 350px;
  }

  .details-card:not(:last-child) {
    margin-bottom: 0rem;
  }
  .details-topbar > *:not(:first-child) {
    padding-top: 0rem;
  }
}
.details-card > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8em;
}
.details-card > span:not(:last-child) {
  padding-bottom: 1em;
}
.details-container .main-body {
  margin-top: 2rem;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
}
@media (min-width: 600px) {
  .footer {
    justify-content: flex-end;
  }
}
.profile-card {
  background-color: #fff;
  padding: 1rem;
  width: 80%;
  margin: 2rem auto 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 5px 25px #eee;
  position: relative;
  overflow: hidden;
}

.avatar {
  display: block;
  border-radius: 1rem;
  /* margin: 2rem auto; */
  position: relative;
}
.profile-content {
  margin-left: 2rem;
}
.circle {
  position: absolute;

  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 50px solid #00962c33;
}
.circle:nth-child(odd) {
  top: -40%;
  right: -20%;
}
.circle:nth-child(even) {
  bottom: -20%;
  left: -10%;
  border: 40px solid #00962c33;
}

.table-container {
  overflow-x: auto;
  /* margin-right: 1rem; */
  width: 100%;
}
.table-row {
  font-size: 0.9rem;
  width: 100%;
  /* padding: 0 0.5rem; */
  background-color: #fff;
}
.table-row:hover {
  opacity: 0.8;
  cursor: pointer;
}

.owing-card {
  width: 80%;
  padding: 3rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem auto 0;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 3px 10px #00000022;
}
.owing-card .circle {
  top: -25%;
  right: -15%;
  opacity: 0.5;
}
.owing-card > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.owing-card .amount-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.owing-card .amount-text .amount {
  font-size: 3rem;
  font-weight: 600;
}

@media (max-width: 767px) {
  .owing-card {
    width: 90%;
  }
  .owing-card .amount-text {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .owing-card .amount-text .amount {
    font-size: 2.5rem;
  }
}
