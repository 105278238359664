* {
  transition: 0.3s;
}
.logo {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.dp {
  height: 220px;
  position: relative;
  border-radius: 1rem;
  margin: 1.5rem 0;
  padding: 1rem 0;
  text-align: center;
  box-shadow: 0 5px 20px #ddd;
  overflow: hidden;
  border: 1px solid #00962c22;
}
.dp-collapsed {
  height: 220px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  box-shadow: none;
}
.dp .circle {
  height: 170px;
  width: 170px;
  border: 30px solid #00962c22;
  position: absolute;
  bottom: 50%;
  left: 50%;
  border-radius: 50%;
  z-index: -1;
}

.hide {
  opacity: 0;
}

.arrow-collapse {
  width: 30px;
  height: 30px;
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  top: 8%;
  right: -15px;
  transform: rotateY(0deg);
  border-radius: 50%;
  z-index: 2;
  background-color: #fff;
}
.arrow-collapse > * {
  padding: 0;
}
.arrow-collapse:hover {
  transition: 0.3s !important;
  color: #00962c;
}
.roatateArrow {
  transform: rotateY(180deg);
}
.dp:hover {
  cursor: pointer;
}
.dp > div {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  padding: 0.5rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dp img {
  border-radius: 10%;
}
.dp-collapsed img {
  /* margin: 75px 0; */
  border-radius: 50%;
  box-shadow: 0 5px 10px #ddd;
}
.dp p {
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 1rem;
  text-transform: capitalize;
}
.dp span {
  opacity: 0.5;
}
.side__nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  padding: 5%;
  /* width: 100%; */
  background: #fff;
  display: grid;
  grid-template-rows: 1fr auto;
  overflow-y: auto;
}
.side__nav::-webkit-scrollbar {
  width: 2px;
}

.side__nav__close {
  display: none;
}
@media screen and (min-width: 767px) {
  .layout {
    display: grid;
    grid-template-columns: auto 1fr;
    overflow: hidden;
  }
  .layout > * {
    height: 100vh;
  }
  .layout > div {
    position: relative;
  }
  .arrow-collapse {
    display: flex;
  }
  .side__nav {
    position: sticky;
    width: 260px;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .side__nav:hover {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .side__nav__close {
    display: grid;
  }
  .cancel button {
    display: none !important;
  }
  .collapse-nav {
    width: 80px;
    /* overflow-y: auto;
    overflow-x: hidden; */
  }
}

.main__container {
  background: #fff;
  position: relative;
  overflow: hidden;
  height: 100vh;
}
.main__container > * {
  height: 100%;
  overflow: hidden;
}

.menuItem {
  padding-left: 1.1rem !important;
}
.menu {
  background-color: #fff0 !important;
}
.menuItem::after {
  content: "";
  border-right: 0px !important;
}
.menuItem svg {
  /* padding-left: 0.5rem; */
  height: 1.3rem !important;
}

.bell {
  position: sticky;
  top: 0;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  left: 0;
  right: 0;
}
.bell button {
  border: 0;
  border: radius 1rem;
  color: #00962c;
  outline-color: #00962c;
  padding: 0.25rem 0.5rem 0 0.5rem;
}
.cancel {
  display: grid;
  grid-template-columns: 1fr auto;
}
.cancel button {
  height: 32px;
  display: grid;
  place-items: center;
  background-color: transparent;
  border: 0;
  outline-color: #00962c;
  color: #00962c;
}

.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  font-family: Montserrat;
}

.not-found h1 {
  font-size: 8rem;
  padding: 0;
  margin: 0;
  color: #00962c;
  font-weight: 200;
}
.not-found h3 {
  font-size: 2.5rem;
  padding: 0;
  margin: 0;
  opacity: 0.6;
}
