.comment-wrap {
  border-radius: 20px;
  padding: 20px;
  background-color: #ffffffdd;
  margin-top: 1rem;
  /* max-width: 500px; */
}
.header {
  display: flex;
  align-items: center;
}
.header > * {
  margin: 0;
}
.comment-wrap .image {
  /* padding: 10px; */
  width: 40px;
  height: 40px;
  background-color: #aa22dd22;
  border-radius: 50%;
  margin-right: 0.6rem;
  font-weight: 900;
  font-size: 1.5rem;
  display: grid;
  place-items: center;
}
.comment-wrap .header {
  margin-bottom: 1rem;
}

.footer {
  padding: 1rem 0 0 0;
  font-size: 0.7rem;
  align-items: flex-end;
  /* display: flex;
  justify-content: space-between;
  text-align: right; */
}
.footer > span {
  opacity: 0.5;
}
