.item {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  padding: 0.5rem 0.5rem;
  max-width: 100%;
  margin: 0 auto;
  width: 270px;
  min-width: 250px;
  border: 1px solid transparent;
  box-shadow: 0px 2px 5px #ddd;
}
.item:not(:last-child) {
  margin-bottom: 1rem;
}
@media (min-width: 600px) {
  .item {
    margin-bottom: 0rem;
  }
  .item:not(:last-child) {
    margin-bottom: 0rem;
  }
}
.item:hover {
  cursor: pointer;
  border: 1px solid #00962c;
}
.item-img {
  padding: 0.3rem 0.4rem;
  background-color: #00962c1a;
  border-radius: 5px;
}
.item-text {
  padding: 0;
  width: 100%;
  height: 50;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin-left: 0.5rem;
  padding: 0.5rem 0;
}
.item-text * {
  text-transform: capitalize;
}
.item-main-text,
.item-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.item-footer {
  align-items: flex-end;
}
