.container {
  /* height: calc(100% - 150px) !important; */
  display: grid;
  /* background: #e9e9e9; */
  grid-template-rows: auto 1fr;
}
.main {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0px;
}
.content {
  /* background-color: #fff; */
  display: grid;
  grid-template-rows: auto 1fr auto;
  border-radius: 15px;
  /* padding: 1rem; */
  height: 85vh;
}
.content_multi,
.material-select {
  /* background-color: #fff; */
  display: grid;
  grid-template-rows: auto 1fr auto;
  /* gap: 20px; */
  height: calc(100%);
  height: 81vh;
  /* border-radius: 24px; */
  /* padding: 0rem; */
  overflow: hidden;
}

.mat-text {
  text-align: center;
  padding: 1rem;
}
.mat-name {
  text-transform: capitalize;
  margin-bottom: 0.5rem;
}
.category-items {
  display: grid;
  /* grid-gap: 1rem; */
  gap: 1rem;
  /* grid-row-gap: 0.5rem; */
  grid-template-columns: 1fr 1fr;
  overflow-y: auto;
  /* border: 2px solid yellow; */
  /* grid-template-columns: repeat(auto-fit, minmax(50%, 1fr)); */
}
.category {
  border: 1px solid transparent;
  /* padding: 0.5rem 0rem; */
  border-radius: 15px;
  /* background-color: #fff; */
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(85vh - 100px);
  /* border: 2px solid yellow; */
}

.cart-section > * {
  position: fixed;
}
.tabs {
  display: flex;
  gap: 5px;
  justify-content: center;
}
.tabs * {
  flex: auto;
  max-width: 50px;
  height: 5px;
}
.tabs > span {
  background: #00962c;
}
.cart-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart-button > *:last-child {
  margin-left: 10px;
}

.inactive-tab {
  background: #f9f9f9 !important;
}

.category h2 {
  width: 100%;
}

.cart {
  display: none;
}

.carttop {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: 992px) {
  .container {
    grid-template-rows: 1fr;
  }
  /* .container > :first-child {
    display: none;
  } */
  .category {
    padding: 0.5rem 1rem;
  }
  /* .content_multi {
    padding: 0.5rem;
  } */
  .cart {
    width: 360px;
    max-width: 100%;
    background: #ffffff;
    height: 100%;
    border: 1px solid #92c064;
    border-radius: 24px;
    display: grid;
    grid-template-rows: auto 1fr auto;
    overflow: hidden;
    max-height: 400px;
  }
}

/* .material {
  border: 1px solid transparent;
  background-color: #fff;
  padding: 1rem;
  border-radius: 15px;
  text-align: center;
  padding-bottom: 0;
} */
.material {
  overflow: hidden;
  height: 70vh;
  /* margin-bottom: 0.5rem; */
  overflow: hidden;
  overflow-y: auto;
  position: relative;
}
.qty-wrap {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #ffffffdd;
  /* backdrop-filter: blur(10px); */
  /* border-radius: 20px; */
  animation: slide-up 1.5s;
  z-index: 2;
  box-shadow: 0 4px 5px #00000011;
  padding: 0.1rem 0;
  padding-top: 2rem;
}
.qty-wrap > span {
  margin-left: 0.5rem;
  margin-bottom: 1rem;
}
.qty-wrap > span b {
  font-weight: 500;
  text-transform: capitalize;
}

@keyframes slide-up {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  50% {
    transform: translateY(30px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
.qty {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.brand-container {
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  overflow: hidden;
  overflow-x: auto;
  justify-content: center;
  /* overflow-y: ; */
  /* padding: 1rem 1rem; */
  margin-bottom: 1rem;
  position: relative;
}
.brand-card {
  background-color: #fff;
  /* min-width: 150px; */
  /* width: 170px; */
  height: 120px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px #ccc;
  opacity: 0.5;
  transition: 0.3s;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: grayscale(50%);
  /* padding: 1rem; */
  overflow: hidden;
}
.brand-card:not(:last-child) {
  margin-right: 1rem;
}
.brand-card:hover {
  box-shadow: 0px 2px 15px #eee;
  filter: grayscale(50%);
  cursor: pointer;
}
.selected-brand {
  opacity: 1;
  transform: scale(1.08);
  filter: grayscale(0%);
  box-shadow: 0px 2px 15px #eee;
}
.sizes {
  /* display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center; */
  width: 100%;
  display: grid;
  gap: 1rem;
  /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
  overflow: hidden;
  /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
  grid-template-columns: repeat(2, 1fr);
}

.nav__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2px;
  /* margin-top: 0rem; */
}

.quotations-card {
  background-color: #f9f9f9;
  width: 100%;
  padding: 1.3rem;
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: space-between;
}
.quotations-card > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.quotations-card > span:last-child {
  color: #00962c;
}
.white {
  background-color: #fff;
}

@media screen and (max-width: 600px) {
  .main {
    gap: 0px;
  }
  .content_multi,
  .material-select {
    height: 85vh;
  }
  .brand-card {
    background-color: #fff;
    /* min-width: 60%; */
  }
  .category-items {
    grid-template-columns: 1fr;
  }
}
.material-info {
  background-color: #888;
  color: #fff !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  border-radius: 10px 10px 0 0;
  padding: 1rem 1rem;
  text-transform: capitalize;
}
.material-info img {
  border-radius: 10px;
}
.material-info div span {
  display: flex;
  align-items: center;
}
.material-info span aside {
  margin-left: 1rem;
}

.material-info h4,
.material-info h5 {
  color: #fff !important;
}
.material-info h4 {
  font-size: 0.9rem;
}
.material-info h5 {
  margin-left: 0.5rem;
  font-size: 0.9rem;
  font-weight: 700;
}
.material-info span {
  display: flex;
  align-items: flex-start;
  color: #fff !important;
}
.top-info {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.location-btns-wrap {
  margin-top: 1rem;
}
.location-btn {
  padding: 0.6rem 1rem;
  border: none;
  border: 1px solid#00962c;
  color: #00962c;
  background-color: transparent;
  outline: none;
  border-radius: 10px;
  /* font-size: 1.2rem; */
  /* font-weight: 500; */
  transition: 0.3s;
}
.location-btn:not(:last-child) {
  margin-right: 1rem;
}

.location-btn:hover {
  cursor: pointer;
  background-color: #00962c;
  color: #fff;
}
.active,
.location-btn:active {
  background-color: #00962c;
  color: #fff;
}
.active:hover {
  background-color: #fff;
  color: #00962c;
}

.add-schedule {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
}
.add-schedule p {
  padding: 0;
  margin: 0;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}
.plus-btn {
  font-size: 2.5rem;
  color: #00962c;
  cursor: pointer;
  padding: 0;
  margin: 0;
  line-height: 0;
}
