.nav {
  display: flex;
  flex-direction: column;
}
.nav a {
  display: block;
  padding: 1rem 1.5rem;
  color: #000;
  font-weight: bold;
  margin-inline: 1rem;
  border-bottom: 1px solid #f1f1f1;
}
.list {
  /* max-width: 500px; */
  margin: auto;
  padding: 2rem 1rem;
  overflow-y: auto;
  height: 80vh;
}
.body {
  background-color: #f8f8f8;
  border-radius: 0 10px 0;
}

.bank-container {
  text-align: center;
  width: 100%;
}

.trucklist,
.moqlist {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 5px;
  margin-top: 1rem;
  box-shadow: 0 2px 5px #00000022;
  min-width: 100%;
}
.moqlist {
  padding: 0rem;
}
.trucklist > div {
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.trucklist h3 {
  font-weight: bold;
  margin-right: auto;
  margin-bottom: 0;
}
.trucklist ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  padding-top: 0.5rem;
  gap: 1ch;
}
.trucklist ul li {
  background-color: #e0efe5;
  border-radius: 9px;
  padding: 0 5px;
}

.main {
  display: grid;
  grid-template-columns: 260px 1fr;
  min-height: 100%;
  max-width: 100%;
  overflow: auto;
}
/* .main * {
  max-width: 100%;
} */
@media screen and (max-width: 768px) {
  .main {
    grid-template-columns: auto;
  }
}

.table {
  background: #fff;
  border-radius: 1rem;
  padding: 2rem;
  margin: 0.25rem auto;
  margin-top: 1rem;
  max-width: 500px;
  overflow: auto;
  box-shadow: 0 2px 5px #00000022;
  font-size: 1rem;
}

@media screen and (max-width: 480px) {
  .table {
    padding: 1rem;
    margin: 0.25rem;
    font-size: 0.85rem;
  }
}
.table th {
  text-align: start;
  font-weight: 400;
}
.table table {
  width: 100%;
}
.table td {
  text-align: end;
  font-weight: bold;
}
.table td::first-letter {
  text-transform: uppercase;
}
