.card, .variant-card {
  /* width: 300px; */
  min-width: 270px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  position: relative;
  gap: 1rem;
  color: var(--white);
  background-color: var(--primaryDarker);
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s;
  font-size: 0.8rem;
  position: relative;
}
.card:hover {
  background-color: var(--primaryDark);
}
.variant-card {
  background-color: var(--white);
  color: black;
  gap: 0.6rem;
  transition: 0.2s;
}
.variant-card:hover {
  background-color: var(--primaryDark);
  color: var(--white);
}
.cart-card:hover {
  background-color:  var(--white);
  color: black;
}
.delete {
  position: absolute;
  top: 6px;
  right: -6px;
  opacity: 0;

}
.image {
  width: 150px;
  height: 150px;
  overflow: hidden;
  padding: 0.25rem;
  background-color: var(--white);
  display: grid;
  place-content: center;
}
.variant-card .image {
  max-width: 100px;
  overflow: hidden;
  height: 100px;
  /* border: 1px solid red; */
  padding: 0rem;
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.56rem;
}
.title {
  text-transform: capitalize;
  font-size: 1.1rem;
}
.out-of-stock p, .out-of-stock img {
  opacity: 0.5;
  filter: grayscale(100%);
}
.out-of-stock:hover {
  background-color: var(--primaryDarker);
}

.out-of-stock-badge {
  padding: 0.2rem 1rem;
  background-color: var(--primaryDark);
  color: var(--white);
  border-radius: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 0.7rem;
}
.variant-card:hover .delete {
  right: 6px;
  opacity: 1;
}